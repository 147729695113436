<template>
  <div>
    <v-layout column wrap>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h5 class="font-weight-light">LIST OF PAIDS</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-col>
          <v-select
            v-model="month_of_id"
            class="mx-2"
            dense
            outlined
            label="Month Of"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            :rules="rules.combobox_rule"
            @change="selected_data"
          ></v-select>
          Print:
          <v-icon
            v-if="!printingss && data.length > 0"
            color="success"
            @click="print_data()"
          >
            {{ icons.mdiPrinter }}
          </v-icon>
          <v-progress-circular
            color="info"
            indeterminate
            v-if="printingss"
          ></v-progress-circular>
        </v-col>
        <v-simple-table dense class="pt-2">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">
                Name
              </th>
              <th class="text-center text-uppercase">
                Details
              </th>
              <th class="text-center text-uppercase">
                Specify
              </th>
              <th class="text-center text-uppercase">
                Paid Amount
              </th>
              <th class="text-center text-uppercase">
                Amount
              </th>
              <th class="text-center text-uppercase">
                Balance
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in data"
              :key="item.id"
            >
              <td>
                {{ item.name }}
              </td>
              <td class="text-center">
                {{ item.details }}
              </td>
              <td class="text-center">
                {{ item.specify }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.paid_amount) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.balance) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
import {
  mdiAccountGroup,
  mdiAccountPlus
  ,
  mdiAccountSearch,
  mdiAccountSearchOutline,
  mdiAlertOutline,
  mdiCash,
  mdiCash100,
  mdiCloudUploadOutline, mdiPrinter
} from '@mdi/js'
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      data: [],
      month_of_id: '',
      month_of_items: [],
      printingss: false,
      icons: {
        mdiCash100,
        mdiAccountSearchOutline,
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiAccountPlus,
        mdiCash,
        mdiPrinter,
      },
    }
  },
  mounted() {
    this.get_transaction_month()
      .then(response => {
        this.month_of_items = response.data
      })
      .catch(error => {
        console.log(error)
      })
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['name', 'position']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'company_name', 'company_contact', 'company_address', 'company_sec']),
  },
  methods: {
    ...mapActions('transaction_month', ['get_transaction_month',]),
    ...mapActions('branch_data', ['list_of_paid',]),
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selected_data() {
      this.selectedDepositId = -1
      this.data_items = []
      this.data_items2 = []
      this.total_amount = '0'
      this.list_of_paid({
        month_of_id: this.month_of_id
      })
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    print_data() {
      this.printingss = true
      var imgData = this.company_logo
      var payments_array = []
      var widths = []
      var info_array = []

      widths = [180, 100, 60, 60, 60, 60, 60]
      payments_array.push([
        {text: 'Name', alignment: 'left', style: 'main_info'},
        {text: 'Details', alignment: 'left', style: 'main_info'},
        {text: 'Specify', alignment: 'left', style: 'main_info'},
        {text: 'Paid Amount', alignment: 'left', style: 'main_info'},
        {text: 'Total Amount', alignment: 'left', style: 'main_info'},
        {text: 'Balance', alignment: 'left', style: 'main_info'},
      ])
      this.data.forEach(function (item) {
        payments_array.push([
          {
            text: item.name,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.details,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.specify,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: (item.paid_amount / 1)
              .toFixed(2)
              .replace(',', '.')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: (item.amount / 1)
              .toFixed(2)
              .replace(',', '.')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: (item.balance / 1)
              .toFixed(2)
              .replace(',', '.')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            alignment: 'left',
            style: 'tableExample2'
          },
        ])
      })
      info_array = [
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Month of: ',
                {
                  text: this.month_of_id,
                  style: 'main_data',
                },
              ],
            },
          ],
        },
      ]
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: {
          width: 612,
          height: 936,
        },
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  this.company_name,
                  {text: this.company_address, style: 'subheader'},
                  {
                    text: this.company_contact,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '_______________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    text: 'LIST OF PAIDS',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          {
            columns: [{text: ' '}],
          },
          {
            stack: info_array,
          },
          {
            columns: [{text: ' '}],
          },
          {
            table: {
              widths: widths,
              body: payments_array,
              style: {fontSize: 1},
            },
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PROCESSED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'DEPARTMENT RECEIVER',
                    style: 'main_data_2',
                  },
                  {
                    text: 'RECEIVED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.name,
                    style: 'main_data_2',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.position,
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'VERIFIED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_________________________________________',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'BOARD OF DIRECTOR',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
            ],
          },
        ],
        footer: {
          // columns: [
          //     {
          //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //         alignment: 'center',
          //         style: 'tableExample'
          //     }
          // ]
        },
        styles: {
          tableExample: {
            fontSize: 9,
          },
          tableExample2: {
            fontSize: 7,
          },
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 11,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 11,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
      this.printingss = false
    }
  },
}
</script>
