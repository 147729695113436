<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Details
            </th>
            <th class="text-center text-uppercase">
              Specify
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Balance
            </th>
            <th class="text-center text-uppercase">
              SOA
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td class="text-center">
              {{ item.branch_information.branch_code }}
            </td>
            <td class="text-center">
              {{ item.room_no }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ formatPrice(get_balance(item)) }}
            </td>
            <td class="text-center">
              <v-icon
                v-if="!printingss && data.length > 0"
                color="success"
                @click="print_data(item)"
              >
                {{ icons.mdiPrinter }}
              </v-icon>
              <v-progress-circular
                color="info"
                indeterminate
                v-if="printingss"
              ></v-progress-circular>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import {
  mdiAccountPlus,
  mdiAccountSearchOutline,
  mdiAlertOutline, mdiCash,
  mdiCloudUploadOutline,
  mdiPrinter,
  mdiCash100
} from "@mdi/js";
import {mapGetters} from "vuex";

export default {
  setup() {
    return {
      printingss: false,
      icons: {
        mdiCash100,
        mdiAccountSearchOutline,
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiAccountPlus,
        mdiCash,
        mdiPrinter,
      },
    }
  },
  props: {
    data: Array,
    member_info: Object,
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['name', 'position']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo',]),
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    get_balance(value) {
      let total = parseFloat(value.amount)
      let payment = 0
      value.branch_data_payments.forEach(function (item) {
        payment += parseFloat(item.amount)
      })
      return parseFloat(total - payment)
    },
    print_data(value) {
      this.printingss = true
      let data=value
      var imgData = this.company_logo
      var payments_array = []
      var widths = []
      var payment = 0
      var info_array = []

      widths = [45, 50, 55, 50, 80, 70]
      payments_array.push([
        {text: 'Amount', alignment: 'left', style: 'main_info'},
        {text: 'Invoice Date', alignment: 'left', style: 'main_info'},
        {text: 'Invoice #', alignment: 'left', style: 'main_info'},
        {text: 'Remarks', alignment: 'left', style: 'main_info'},
        {text: 'Status', alignment: 'left', style: 'main_info'},
      ])
      value.branch_data_payments.forEach(function (item) {
        payments_array.push([
          {
            text: (item.amount / 1)
              .toFixed(2)
              .replace(',', '.')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.or_date,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.or_no,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.remarks,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.deposit_slip_id === null ? 'NOT YET DEPOSITED' : 'DEPOSITED',
            alignment: 'left',
            style: 'tableExample2'
          },
        ])
      })
      info_array = [
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Lessee Name: ',
                {
                  text: this.member_info.company_name,
                  style: 'main_data',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Contact #: ',
                {
                  text: this.member_info.contact_no,
                  style: 'tableExample2',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Address: ',
                {
                  text: this.member_info.address,
                  style: 'tableExample2',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Details: ',
                {
                  text: value.branch_information.branch_code+' ('+value.room_no+') -> Total:'+value.sqm+'x'+this.formatPrice(value.amount_per_sqm)+'='+this.formatPrice(value.amount),
                  style: 'main_data',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Balance: ',
                {
                  text: this.formatPrice(this.get_balance(value)),
                  style: 'main_data',
                },
              ],
            },
          ],
        },
      ]

      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: {
          width: 612,
          height: 936,
        },
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE BUILDINGS',
                  {text: 'SAN JOSE, DIGOS CITY DAVAO DEL SUR 8002', style: 'subheader'},
                  {
                    text: 'Contact #: 09',
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '_______________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    text: 'LESSEE STATEMENT OF ACCOUNT (SOA)',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          {
            columns: [{text: ' '}],
          },
          {
            stack: info_array,
          },
          {
            columns: [{text: ' '}],
          },
          {
            table: {
              widths: widths,
              body: payments_array,
              style: {fontSize: 1},
            },
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PROCESSED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'DEPARTMENT RECEIVER',
                    style: 'main_data_2',
                  },
                  {
                    text: 'RECEIVED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.name,
                    style: 'main_data_2',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.position,
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'VERIFIED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_________________________________________',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'BOARD OF DIRECTOR',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
            ],
          },
        ],
        footer: {
          // columns: [
          //     {
          //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //         alignment: 'center',
          //         style: 'tableExample'
          //     }
          // ]
        },
        styles: {
          tableExample: {
            fontSize: 9,
          },
          tableExample2: {
            fontSize: 7,
          },
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 11,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 11,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
      this.printingss = false
    }
  }
}
</script>
